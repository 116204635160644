@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:  "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}






.swal2-container {
  z-index: 1500;
}

.qrScannerContainer p::before {
  content: '';
  display:inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #18c89b;
  position: relative;
  right: 4px;
}
.qrScannerContainer p {
  color: #18c89b;
  position: absolute;
  bottom: -30px;
  left: 38%;
  font-size: 16px;
  font-weight: 600;
  animation: blinker 1.5s linear infinite;
  font-family: sans-serif;
  text-transform: uppercase;
}


.qrScannerContainer span.searcher {
  position: absolute;
  left: 25%;
  top: 22%;
  width: 50%;
  height: 5px;
  background-color: #18c89b;
  box-shadow: 0 0 10px 1px #18c89b,
              0 0 1px 1px #18c89b;
  z-index: 1;
  animation: move 1.7s cubic-bezier(0.15,0.54,0.76,0.74);
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.qrScannerContainer:before,
.qrScannerContainer:after,
.qrScannerContainer em:after,
.qrScannerContainer em:before {
  border-color: #18c89b;
  content: "";
  position: absolute;
  width: 45px;
  height: 46px;
  border-style: solid;
  border-width: 0px;
}
.qrScannerContainer:before {
  left: 20%;
  top: 20%;
  border-left-width: 5px;
  border-top-width: 5px;
  z-index: 10;
  border-radius: 5px 0 0 0;
}
.qrScannerContainer:after {
  right: 20%;
  top: 20%;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 5px 0 0;
}
.qrScannerContainer em:before {
  left: 20%;
  bottom: 20%;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 5px;
}
.qrScannerContainer em:after {
  right: 20%;
  bottom: 20%;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 5px 0;
}

@keyframes move {
  0%,
  100% {
    top: 22%;
    
  }
 
  100% {
    top: 77%;

  }
}

@keyframes blinker {  
  50% { opacity: 0; }
}
